.tariff-room-images {
  max-width: 350px;
  max-height: 200px;
}

.carousel-control-next-icon {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEX////MzMzJycnV1dXm5ubs7Oz8/PzPz8/o6Ojc3Nzw8PD5+fnS0tLh4eHz8/PX19c2o7NKAAACq0lEQVR4nO3d227qMBBGYeOcSALk/d+2UKTubpGDhyJ5/tFaF72s/MmOneQipPSH5q69juP1PPV/+S9uu41Dzvl0Ot3/5qULh5yaB+5feWhrD+mzjf/7nsZb7VF9rnl4BT6M59oD+1TzsOZ7EKOs1GZ1BgMR201gEOK87QtCvO5MYQhiv7XNhCFO+1MYgLhy1gcjNodAdWIBUJvYHy9SceJcJhQmlgp1iYWrVJlYCtQlLuGJB7elAYg3g1CUeDEINYlnyyRqEo8en/SJx89P8kTLdipKXOITt98nQtQJIkSJIEKUCCJEiSBClAgiRIkgQpQIYgQiLxkhSgQRokQQIUoEEaJEECFKBBGiRBAhSgQRokQQIUoEEaJEECFKBBGiRBAhSgQRokQQIUoEEaJEEF+Igt9HtRKn2gO2ZyXOtQdsz0hcao/3jWxExXVqJDa1h/tOJmKW/KS2hah4KCYbUXGvSTZi7bG+WTkxq/5EQcmnbZ9CwUP/uz68sHwORVdp+XU41B7qexn2Us3TwgDUPPFNt22KG40JqLhIw99424Bj7eHaMz7j6x2Gxid8vTVqBHa1x2sOIEDnAQToPIAAnQcQoPMAAnQeQIDOAwjQeQABOg8gQOcBBOg8gACdBxCg8wACdB5AgM4DCNB5AAE6DyBA5wEE6DyAAJ0HEKDzAAJ0HkCAzgMI0HkAATpviQ4cowOn6MB+CA5MremDMoJA0xQqAk1XoSQwjdGB6RIdWPwFR1Vg6ksvQ1VgmguFssDSOdQFFgqFgWV7qTSw5DzUBqbucJmKA48PRHVgStf9SdQHHkxiAGBK551JDAHcexEVBHg/EzeIYYCpXyfGAd6JqwtV8Tdgtnt5H5UbxS9t7zWP+RcyXwKt0J/m9pKfDWOsBfqr/jZ13c338vwCXZIxH3ZkdHcAAAAASUVORK5CYII=");
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tariff-room-images {
    max-width: 210px;
    max-height: 140px;
  }
}
.nav {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 820px) {
  .nav {
    display: none;
  }
}

.nav-logo {
}

@media only screen and (min-width: 768px) and (max-width: 820px) {
  .nav-logo {
    width: 100%;
  }
}

.nav-bar-cust {
  top: -40px !important;
  position: absolute !important;
  background-color: black !important;
}

.nav-top {
  margin: 0 !important;
}

.ab-con {
  width: 104%;
}
.ab-img {
  width: 262px;
}

.hidden {
  display: none;
}

.tab-nav {
  background-color: black !important;
  top: -100px !important;
}

.tab-nav-img {
  max-width: 140px !important;
}
.navbar-close-cust {
  width: 26px;
  height: 26px;
  color: white;
}

.frm_connectivity-cust {
  background-color: #f5eeee !important;
}

.gib {
  display: flex !important;
  flex-direction: "row" !important;
}

.amt-nav {
  display: flex;
}

.ab-ht-ds-img {
  min-width: 487px;
}
.abmm {
  margin: 0 !important;
}

.ab-bt-con {
  display: flex;
  justify-content: center;
}

.trf-nav {
  top: 0px !important;
}
.ph-nav {
  background-color: black !important;
  top: -40px !important;
}

.ph-nav-img {
  width: 140px !important;
}

.bnr {
  min-height: 100vh;
}

.amt-nv-lnk {
  margin-top: 0px;
}

.amt-ds-wrp {
}
.amt-des-con {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}
.amt-des {
  display: flex;
  max-height: 48px;
  min-height: 48px;
  width: 265px;
  background-color: #f0adad3d;
  align-items: center;
  border-radius: 4px;
  position: relative;
  margin: 5px;
  padding: 8px;
}
.amt-des-icon {
  color: #b50000;
  width: 32px;
  height: 29px;
  font-size: 29px;
  margin-right: 10px;
}

.amt-des-flt-rt {
}

.amt-tl-des-two {
  top: 5px !important;
}
.amt-tl-des-one {
  top: 5px !important;
}

.cont-us {
}
.cont-us-infb {
  align-items: center;
}

.bck-tp {
}
.ftr-tab-sld {
  width: 50%;
  background-color: transparent;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
}
.ftr-rm-sld-cont {
  width: 50%;
  background-color: transparent;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
}
.ftr-rm-sld-cont2 {
  width: 50%;
  background-color: transparent;
  display: flex !important;
  flex-direction: row !important;
  justify-content: start;
  align-items: center;
}
.ftr-roomtl-cont {
  width: 50%;
  margin: 20px;
  justify-content: center;
}
.ftr-rm-icon-cont {
  display: flex;
  background-color: white;
  width: 50%;
  height: 53px;
  justify-content: space-evenly;
  position: absolute;
  left: 25px;
  right: 25px;
  padding: 15px;
  top: 190px;
}
.feature-room-slider-cust {
  width: 100% !important;
}
.ftr-rm-box {
  background: transparent;
}
.ftr-rw-cst {
  display: flex;
  justify-content: space-between;
}
.bck-tp:hover {
  background-color: #222 !important;
}
@media only screen and (max-width: 380px) {
  .ph-amt-nav-lnk {
    width: 80px !important;
  }
}
@media only screen and (min-device-width: 540px) and (max-device-width: 820px) {
  .room-img-wrap-cust {
    height: 100% !important;
  }
  .arr {
    display: flex;
    flex-direction: row;
  }
  .arr-cont {
    margin-right: 10px;
    width: 70px;
    height: 70px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ft-room-cust {
    width: calc(100% * 3);
  }
}
@media only screen and (min-device-width: 540px) and (max-device-width: 819px) {
  .room-img-wrap-cust {
    height: 100% !important;
  }
}
@media only screen and (max-width: 711px) {
  .ab-con {
    width: 100%;
  }
  .ab-ht-ds-img {
    min-width: 360px;
  }
  .ab-img {
    width: 100%;
  }
  .amt-ds-wrp {
    display: flex;
    flex-direction: column;
  }
  .amt-tl-des-two {
    top: -4px !important;
  }
  .amt-tl-des-one {
    top: 5px !important;
  }
  .ph-amt-des {
    width: 80% !important;
  }
  .cont-us-infb {
    margin-bottom: 20px;
    align-items: center;
  }
}
@media only screen and (min-device-width: 712px) and (max-device-width: 819px) {
  .amt-nav {
    flex-wrap: nowrap;
  }
  .amt-nv-lnk {
    margin-top: -20px;
  }
  .ph-nav {
    top: -100px !important;
  }
  .amt-des {
    width: 222px;
  }
  .cont-us {
    display: flex;
    flex-direction: column !important;
  }
  .cont-us-infb {
    margin-bottom: 20px;
    align-items: center;
  }
  .room-img-wrap-cust {
    height: 100% !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .ab-con {
    width: 100%;
  }
  .ab-img {
    width: 570px;
    align-self: center;
  }
  .ab-ht-ds-img {
    width: 100%;
  }
  .amt-nv-lnk {
    margin-top: -20px;
  }
  .amt-des {
    display: flex;
    max-height: 48px;
    min-height: 48px;
    width: 237px;
    background-color: #f0adad3d;
    align-items: center;
    border-radius: 4px;
    position: relative;
    margin: 5px;
    padding: 8px;
  }
  .amt-nv-itm {
    width: 80px !important;
  }
  .amt-des-flt-rt {
    float: left !important;
  }
  .amt-tl-des {
    top: 5px !important;
  }
  .cont-us {
    display: flex;
    flex-direction: column !important;
  }
  .cont-us-infb {
    margin-bottom: 20px;
    align-items: center;
  }
  .room-img-wrap-cust {
    height: 100% !important;
  }
  .trf-tab {
    padding-bottom: 0px !important;
  }
}
