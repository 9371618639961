/* Define the keyframes for the animation */
@keyframes hotelMoveLeftToRight {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px); /* Adjust this value as needed */
        opacity: 1;
    }
}

/* Apply the animation to the element */
.hotel-element-to-moveRight {
    animation: hotelMoveLeftToRight 2s linear; /* Adjust duration and timing function as needed */
}

@keyframes hotelDescriptionMoveRightToleft {
    0% {
        transform: translateX(100px); /* Adjust this value as needed */
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

.hotel-description-element-to-moveLeft {
    animation: hotelDescriptionMoveRightToleft 2s linear;
}

@keyframes kolkataMoveLeft {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px); /* Adjust this value as needed */
        opacity: 1;
    }
}

/* Apply the animation to the element */
.kolkata-to-moveLeft {
    animation: kolkataMoveLeft 1s linear; /* Adjust duration and timing function as needed */
}

.hidden {
    opacity: 0;
}

@keyframes promoTag {
    0% {
        transform: translateY(-25px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px); /* Adjust this value as needed */
        opacity: 1;
    }
}

.bd-sec-promo-tag {
    animation: promoTag .5s linear !important;
}

@keyframes title {
    0% {
        transform: translateX(-75px);
        opacity: 0;
    }
    50% {
        transform: translateX(-75px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px); /* Adjust this value as needed */
        opacity: 1;
    }
}

.bd-sec-title {
    animation: title 1.5s linear !important;
}

@keyframes button {
    0% {
        transform: translateY(35px);
        opacity: 0;
    }
    75% {
        transform: translateY(35px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px); /* Adjust this value as needed */
        opacity: 1;
    }
}

.bd-sec-button {
    animation: button 2s linear !important;
}
