/*
  	Flaticon icon font: Flaticon
  	Creation date: 15/06/2020 05:17
  	*/

@font-face {
	font-family: 'Flaticon';
	src: url('../fonts/Flaticon.eot');
	src: url('../fonts/Flaticond41dd41d.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Flaticon.woff2') format('woff2'),
		url('../fonts/Flaticon.woff') format('woff'),
		url('../fonts/Flaticon.ttf') format('truetype'),
		url('../fonts/Flaticon.svg#Flaticon') format('svg');
	font-weight: normal;
	font-style: normal;
}



[class^='flaticon-'],
[class*=' flaticon-'],
[class^='flaticon-'],
[class*=' flaticon-'] {
	line-height: 1;
}

[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-menu:before {
	content: '\f100';
}
.flaticon-call:before {
	content: '\f101';
}
.flaticon-phone:before {
	content: '\f102';
}
.flaticon-envelope:before {
	content: '\f103';
}
.flaticon-message:before {
	content: '\f104';
}
.flaticon-clipboard:before {
	content: '\f105';
}
.flaticon-checklist:before {
	content: '\f106';
}
.flaticon-calendar:before {
	content: '\f107';
}
.flaticon-event:before {
	content: '\f108';
}
.flaticon-man:before {
	content: '\f109';
}
.flaticon-user:before {
	content: '\f10a';
}
.flaticon-group:before {
	content: '\f10b';
}
.flaticon-teamwork:before {
	content: '\f10c';
}
.flaticon-team:before {
	content: '\f10d';
}
.flaticon-back:before {
	content: '\f10e';
}
.flaticon-arrow:before {
	content: '\f10f';
}
.flaticon-tick:before {
	content: '\f110';
}
.flaticon-close:before {
	content: '\f111';
}
.flaticon-accept:before {
	content: '\f112';
}
.flaticon-menu-1:before {
	content: '\f113';
}
.flaticon-upload:before {
	content: '\f114';
}
.flaticon-next:before {
	content: '\f115';
}
.flaticon-download:before {
	content: '\f116';
}
.flaticon-back-1:before {
	content: '\f117';
}
.flaticon-back-3:before {
	content: '\f118';
}
.flaticon-back-2:before {
	content: '\f119';
}
.flaticon-bread:before {
	content: '\f11a';
}
.flaticon-breakfast:before {
	content: '\f11b';
}
.flaticon-coffee:before {
	content: '\f11c';
}
.flaticon-airplane:before {
	content: '\f11d';
}
.flaticon-marker:before {
	content: '\f11e';
}
.flaticon-location-pin:before {
	content: '\f11f';
}
.flaticon-barbecue:before {
	content: '\f120';
}
.flaticon-hotel:before {
	content: '\f121';
}
.flaticon-pixels:before {
	content: '\f122';
}
.flaticon-air-freight:before {
	content: '\f123';
}
.flaticon-world:before {
	content: '\f124';
}
.flaticon-fast-food:before {
	content: '\f125';
}
.flaticon-swimming:before {
	content: '\f126';
}
.flaticon-paper-plane:before {
	content: '\f127';
}
.flaticon-facebook:before {
	content: '\f128';
}
.flaticon-twitter:before {
	content: '\f129';
}
.flaticon-behance:before {
	content: '\f12a';
}
.flaticon-youtube:before {
	content: '\f12b';
}
.flaticon-linkedin:before {
	content: '\f12c';
}
.flaticon-rating:before {
	content: '\f12d';
}
.flaticon-clock:before {
	content: '\f12e';
}
.flaticon-clock-1:before {
	content: '\f12f';
}
.flaticon-credit-card:before {
	content: '\f130';
}
.flaticon-discount:before {
	content: '\f131';
}
.flaticon-user-1:before {
	content: '\f132';
}
.flaticon-like:before {
	content: '\f133';
}
.flaticon-suitcase:before {
	content: '\f134';
}
.flaticon-bed:before {
	content: '\f135';
}
.flaticon-wifi:before {
	content: '\f136';
}
.flaticon-car:before {
	content: '\f137';
}
.flaticon-coffee-cup:before {
	content: '\f138';
}
.flaticon-dice:before {
	content: '\f139';
}
.flaticon-serving-dish:before {
	content: '\f13a';
}
.flaticon-expand:before {
	content: '\f13b';
}
.flaticon-double-angle-pointing-to-right:before {
	content: '\f13c';
}
.flaticon-double-left-chevron:before {
	content: '\f13d';
}
.flaticon-bath:before {
	content: '\f13e';
}
.flaticon-cut:before {
	content: '\f13f';
}
.flaticon-housekeeping:before {
	content: '\f140';
}
.flaticon-groceries:before {
	content: '\f141';
}
.flaticon-shopping-cart:before {
	content: '\f142';
}
.flaticon-kitchen:before {
	content: '\f143';
}
.flaticon-hamburger:before {
	content: '\f144';
}
.flaticon-towel:before {
	content: '\f145';
}
.flaticon-key:before {
	content: '\f146';
}
.flaticon-shield:before {
	content: '\f147';
}
.flaticon-headphones:before {
	content: '\f148';
}
.flaticon-pizza:before {
	content: '\f149';
}
.flaticon-cake:before {
	content: '\f14a';
}
.flaticon-boiled:before {
	content: '\f14b';
}
.flaticon-cookie:before {
	content: '\f14c';
}
.flaticon-cocktail:before {
	content: '\f14d';
}
.flaticon-witness:before {
	content: '\f14e';
}
.flaticon-chat:before {
	content: '\f14f';
}
.flaticon-quote:before {
	content: '\f150';
}
.flaticon-quote-1:before {
	content: '\f151';
}
.flaticon-search:before {
	content: '\f152';
}
.flaticon-grid:before {
	content: '\f153';
}
.flaticon-back-4:before {
	content: '\f154';
}
.flaticon-pen:before {
	content: '\f155';
}
.flaticon-globe:before {
	content: '\f156';
}
.flaticon-home:before {
	content: '\f157';
}
.flaticon-notebook:before {
	content: '\f158';
}
